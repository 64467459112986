@charset 'UTF-8';

// ========================================
// WordPress
// ========================================



.breadcrumb {
    background-color: white;
    width: 100%;
    display: block;
    color: $black;
    font-size: 0.875rem;
    line-height: 1.3;
    padding: 10px;
    @include media(md) {
        padding: 20px;
    }
    .home,
    .post,
    .category {
        padding: 0 5px;
        display: inline;
        vertical-align: middle;
        font-size: 0.875rem;
        line-height: 1.3;
        color: $black;
        transition: color .3s ease-in-out;
        &:hover {
            color: $link;
        }
        span.current-item {
            color: $black;
        }
    }
    .current-item {
        color: $black;
        &:hover {
            color: $black;
        }
    }
}

/* オリジナル
--------------------------------------------- */
.block-post {
    max-width: 960px;
    padding: 0 5%;
    margin-right: auto;
    margin-left: auto;
    &--wrap {
        overflow-x: hidden;
    }
    p {
        text-align: justify;
    }
    h1 + p, h2 + p {
        margin-top: 30px;
    }
    h3 + p, h4 + p {
        margin-top: 20px;
    }
    .wp-block-image + p, p + .wp-block-image {
        margin-top: calc(40px + 40*(100vw - 320px)/1280);
    }
    p + p {
        margin-top: calc(30px + 30*(100vw - 320px)/1280);
    }
}




/* WordPress Native Styles
--------------------------------------------- */
.aligncenter {
    display: block;
    margin: 0 auto;
}
.alignright { float: right; }
.alignleft { float: left; }

img[class*='wp-image-'],
img[class*='attachment-'] {
    max-width: 100%;
    height: auto;
}

.wp-caption {
    max-width: 96%;
    padding: 8px;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    padding: 0;
    width: auto;
    max-width: 100%;
}

.wp-caption p.wp-caption-text {
    margin: 0;
    padding: 5px 0;
    font-size: 1.4rem;
}

.postContent h2 {
    font-size: 2.2rem;
}
.postContent h3 {
    font-size: 2.0rem;
}
.postContent h4 {
    font-size: 1.8rem;
}
.postContent h5 {
    font-size: 1.6rem;
}
.postContent h6 {
    font-size: 1.4rem;
}

.postContent p {
    margin-bottom: 10px;
}
.postContent pre {
    background-color: $thin;
    padding: 8px;
    margin: 16px 0;
    font-size: 1.4rem;
}
.postContent strong {
    font-weight: bold;
}
.postContent em {
    font-style: italic;
}
.postContent ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
}
.postContent ol {
    list-style-type: decimal;
    padding-left: 20px;
    margin-bottom: 20px;
}
.postContent blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    border-left: 7px solid #ccc;
}
.postContent hr {
    border-top: 1px solid #ccc;
    margin: 20px 5px;
}
.postContent .aligncenter,
.postContent div.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.postContent .alignleft {
    float: left;
    margin-right:10px;
}
.postContent .alignright {
    float: right;
}
.postContent .wp-caption-text {
    font-size: 1.2rem;
}


