/*
Version: 1.1.0
Author:コウベビューティー株式会社
Author URI: https://kobe-beauty.co.jp/
 */
@charset "UTF-8";

// Import reset
@import "_reset.scss";

// Import slick
@import "_slick.scss";
@import "_slick-theme.scss";

// Import lightcase
@import 'components/settings';
@import 'components/fonts/font-lightcase';
@import 'components/mixins/presets';
@import 'components/mixins/utilities';
@import 'components/modules/animations';
@import 'components/modules/case';
@import 'components/modules/content';
@import 'components/modules/error';
@import 'components/modules/global';
@import 'components/modules/info';
@import 'components/modules/loading';
@import 'components/modules/navigation';
@import 'components/modules/overlay';

// Import original basic modules
@import "_mixin.scss";
@import "_setting.scss";

// Import wordpress
@import "_wp.scss";

// Import original modules
@import "_module.scss";


// ----------------------------------------
// ラッパー
// ----------------------------------------
.wrapper {
    @include media(md) {
    }
}

// ----------------------------------------
// ヘッダー
// ----------------------------------------
.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px 12px;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    @include media(xl) {
        flex-flow: column nowrap;
        align-items: stretch;
        height: 100%;
        width: 240px;
        padding: 0;
    }
    &-logo {
        @include media(xl) {
            padding: 15px 15px 0;
        }
        .logo {
            line-height: 1.0;
            picture {
                line-height: 1.0;
            }
        }
    }
    &-nav {
        display: none;
        @include media(xl) {
            display: block;
            margin: 30px 0 0;
        }
        .nav-list {
            & > li {
                font-size: 0.875rem;
                font-weight: bold;
                line-height: 1.2;
                position: relative;
                overflow: hidden;
                &:hover {
                    overflow: visible;
                    & > a {
                        border-left: 10px solid $pana-yellow;
                        background-color: $pana-yellow;
                    }
                }
                & > a {
                    display: block;
                    padding: 15px 35px 15px 10px;
                    z-index: 1000;
                    background-color: white;
                    border-left: 5px solid white;
                    color: $black;
                    transition: border-left .3s, background-color 1s;
                }
                svg {
                    position: absolute;
                    display: block;
                    right: 15px;
                    top: 50%;
                    transform: translate(0, -50%);
                    width: 10px;
                    height: 16px;
                    fill: currentColor;
                }
            }
            & .list-child-lv2 {
                position: absolute;
                left: 240px;
                top: 0;
                & > li {
                    font-size: 0.875rem;
                    font-weight: bold;
                    width: 240px;
                    line-height: 1.2;
                    background-color: white;
                    display: block;
                    a {
                        display: block;
                        width: 240px;
                        padding: 15px;
                        z-index: 1000;
                        color: $black;
                        transition: color .3s, background-color .3s;
                        background-color: $pana-yellow;
                       &:hover {
                            background-color: $main;
                            color: white;
                        }
                    }
                }
            }
        }
    }
    &-etc {
        display: none;
        @include media(xl) {
            display: block;
            margin: auto 0 0;
        }
    }
}



#nav-drawer {
    position: relative;
}
.nav-unshown {
    display:none;
}
#nav-open {
    display: inline-block;
    width: 30px;
    height: 22px;
    vertical-align: middle;
    transition: .3s;
    cursor: pointer;
    @include media(xl) {
        display: none;
    }
}
#nav-open span, #nav-open span:before, #nav-open span:after {
    position: absolute;
    height: 3px;
    width: 25px;
    border-radius: 3px;
    background: $black;
    display: block;
    content: '';
    cursor: pointer;
    transition: .8s;
    #frontpage & {
        background: white;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    }
    #frontpage #header.is-animation & {
        background: $black;
        box-shadow: none;
    }
}
#nav-open span:before {
    bottom: -8px;
}
#nav-open span:after {
    bottom: -16px;
}
#nav-close {
    display: none;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    transition: .3s ease-in-out;
}
#nav-content {
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    width: 90%;
    max-width: 280px;
    height: 100%;
    background: $thin;
    transition: .3s ease-in-out;
    transform: translateX(105%);

}
#nav-input:checked ~ #nav-close {
    display: block;
    opacity: .5;
}
#nav-input:checked ~ #nav-open {
    z-index: 999;
    position: fixed;
    right: 280px;
}
#nav-input:checked ~ #nav-open span,
#nav-input:checked ~ #nav-open span:before,
#nav-input:checked ~ #nav-open span:after {
    background: white;
    z-index: 999;
}
#nav-input:checked ~ #nav-open span {
    transform: rotate(45deg);
}
#nav-input:checked ~ #nav-open span:before {
    bottom: 0;
    transform: rotate(-90deg);
}
#nav-input:checked ~ #nav-open span:after {
    bottom: 0;
    opacity: 0;
}
#nav-input:checked ~ #nav-content {
    transform: translateX(0%);
    box-shadow: -6px 0 25px rgba(0,0,0,.15);
}

#nav-menu {
    padding: 30px;
    li {
        text-align: left;
        padding: 10px 0;
        border-bottom: 1px solid $black;
        a {
            display: block;
            color: $black;
            &:hover {
                color: $link;
            }
        }
        ul {
            margin: 10px 0 0;
        }
        li {
            padding: 5px 0px 5px 20px;
            position: relative;
            border-bottom: none;
            &:before {
                content: '';
                position: absolute;
                width: 10px;
                height: 1px;
                background-color: $black;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }
}









// ----------------------------------------
// メイン
// ----------------------------------------
.main {
    display: block;
    z-index: 0;
    background-color: $thin;
    padding: 52px 0 0;
    @include media(xl) {
        padding: 0 0 0 240px;
        width: 100%;
    }
    .page-id-5 & {
        background-color: white;
    }
}




// ファーストビュー
.wf-active {
    .sec-hero {
        .copy {
            font-family: $font-en;
        }
    }
}
.sec-hero {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: calc(560px + 240*(100vw - 320px)/1280);
    img {
        width: auto;
        height: 242px;
    }
    .hero-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 242px;
    }
    .hero-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 242px;
    }
    .title {
        position: absolute;
        top: 50%;
        right: 20px;
        font-size: calc(24px + 48*(100vw - 320px)/1280);
        font-weight: bold;
        transform: translate(0, -50%);
        z-index: 10;
        @include media(sm) {
            right: 10px;
        }
        @include media(md) {
            font-size: 72px;
        }
    }
    .copy {
        font-family: $font-load;
        position: absolute;
        color: rgba(67, 148, 208, .4);
        left: -10px;
        font-size: calc(60px + 100*(100vw - 320px)/1280);
        font-weight: bold;
        transform: translate(0, -50%);
        line-height: 1.07142;
        white-space: nowrap;
        z-index: 8;
        @include media(lg) {
            font-size: 160px;
        }
        &.copy1 {
            top: 42%;
            @include media(sm) {
                top: 39%;
            }
            @include media(md) {
                top: 36%;
            }
            @include media(lg) {
                top: 32%;
            }
        }
        &.copy2 {
            top: 50%;
        }
        &.copy3 {
            top: 58%;
            @include media(sm) {
                top: 61%;
            }
            @include media(md) {
                top: 64%;
            }
            @include media(lg) {
                top: 68%;
            }
        }
    }
}


.wf-active {
    .page-head .sub,
    .post-head .sub {
        font-family: $font-en;
    }
}
.page-head {
    background-color: $pana-pink;
    margin: 0 0 calc(30px + 30*(100vw - 320px)/1280);
    .page_group_school &,
    .post-type-archive-facility &,
    .page_group_childstudio &,
    .page-id-41 &,
    .page-id-48 & {
        background-color: $pana-yellow;
        .title,
        .sub {
            color: $black;
        }
    }
    .page-id-11 &,
    .page-id-9 &,
    .page-id-39 &,
    .page-id-48 & {
        margin: 0;
    }
    .inner {
        position: relative;
        max-width: 960px;
        margin-right: auto;
        margin-left: auto;
        padding: 40px 5%;
        @include media(xl) {
            padding: 60px 0;
        }
        &::after {
            position: absolute;
            content: '';
            display: none;
            bottom: -10px;
            right: 0;
            z-index: 10;
            background-image: url(assets/images/chara-panachildren.png);
            background-size: cover;
            background-repeat: no-repeat;
            width: 120px;
            height: 120px;
            @include media(sm) {
                display: inline-block;
                width: 250px;
                height: 250px;
            }
        }
    }
    .title {
        font-size: calc(30px + 30*(100vw - 320px)/1280);
        letter-spacing: 2px;
        line-height: 1.3;
        font-weight: bold;
        color: white;
    }
    .sub {
        font-size: calc(12px + 2*(100vw - 320px)/1280);
        margin: calc(5px + 5*(100vw - 320px)/1280) 0 0;
        font-style: $font-load;
        font-weight: 700;
        letter-spacing: 5px;
        color: white;
    }
}
.post-head {
    background-color: $pana-yellow;
    margin: 0 0 calc(30px + 30*(100vw - 320px)/1280);
    .inner {
        max-width: 960px;
        margin-right: auto;
        margin-left: auto;
        padding: 40px 5%;
        @include media(xl) {
            padding: 60px 0;
        }
    }
    .title {
        font-size: calc(30px + 20*(100vw - 320px)/1280);
        letter-spacing: 2px;
        line-height: 1.3;
        font-weight: bold;
        color: $black;
    }
    .sub {
        font-size: calc(12px + 2*(100vw - 320px)/1280);
        margin: calc(5px + 5*(100vw - 320px)/1280) 0 0;
        font-style: $font-load;
        font-weight: 700;
        letter-spacing: 5px;
        color: $black;
    }
}

.post-categories {
    li {
        display: inline-block;
    }
    a {
        display: inline-block;
        padding: 1px 10px;
        margin: 0 5px 5px 0;
        background-color: white;
        border-radius: 100px;
    }
}


.sec-slider {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 242px;
    img {
        width: auto;
        height: 242px;
    }
}

.sec-mv {
    position: relative;
    width: 100%;
    height: auto;
    background: center center / cover no-repeat;
    opacity: 0;
    transition: opacity 2s ease-in;
    &:before {
        display: block;
        padding-top: 160px;
        content: '';
        @include media(md) {
            padding-top: 320px;
        }
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        z-index: -1;
    }
    .action & {
        opacity: 1;
        .block {
            .title {
                opacity: 1;
                transform: translate(0, 0);
            }
            .sup {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
    .block {
        position: absolute;
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        line-height: 1.2;
        .title {
            font-size: calc(30px + 10*(100vw - 320px)/1280);
            font-weight: normal;
            line-height: 1.2;
            color: white;
            opacity: 0;
            transform: translate(0, 40px);
            transition: opacity 1s ease-out .5s, transform 1s ease-out .5s;
        }
        .sup {
            font-size: calc(16px + 6*(100vw - 320px)/1280);
            line-height: 1.2;
            color: white;
            margin: 20px 0 0;
            padding: 20px 0 0;
            position: relative;
            opacity: 0;
            transform: translate(0, 40px);
            transition: opacity 1s ease-out 1s, transform 1s ease-out 1s;
            &::before {
                display: block;
                content: '';
                width: 60px;
                height: 1px;
                background-color: $thin;
                top: 0;
                left: 0;
                left: 50%;
                position: absolute;
                transform: translate(-50%, 0);
            }
        }
    }
    &.block-reverse {
        flex-direction: column;
        .sup {
            margin: 0 0 10px;
        }
    }
}





// top
.wf-active {
    .sec-news {
        .news-list {
            .date {
                font-family: $font-en;
            }
        }
    }
}
.sec-news {
    .inner {
        @include media(md) {
            display: flex;
            flex-flow: row nowrap;
        }
    }
    .news-head {
        margin: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-end;
        @include media(md) {
            margin: 0 80px 0 0;
            display: block;
        }
    }
    .news-body {}
    .news-list {
        width: 100%;
        margin: 20px 0 0;
        border-top: 1px solid $pale;
        @include media(md) {
            margin: 0;
            border-top: none;
        }
        li {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            width: 100%;
            padding: 10px 0;
            border-bottom: 1px solid $pale;
            @include media(md) {
                padding: 0;
                border-bottom: none;
                flex-flow: row nowrap;
            }
            & + li {
                @include media(md) {
                    margin: 10px 0 0;
                }
            }
        }
        .date {
            font-family: $font-load;
            font-size: 1rem;
            line-height: 20px;
            width: 95px;
        }
        .cate {
            line-height: 1.0;
            width: 180px;
            &.pana span { color: $pana-pink; }
            &.kids span { color: $pana-skyblue; }
            span {
                display: block;
                font-size: .75rem;
                background-color: $pana-yellow;
                padding: 1px 10px;
                line-height: 20px;
                text-align: center;
                border-radius: 100px;
            }
        }
        .text {
            font-size: 1rem;
            width: 100%;
            line-height: 20px;
            padding: 5px 0 0;
            @include media(md) {
                width: calc(100% - 275px);
                padding: 0 0 0 10px;
            }
        }
    }
}

.sec-topics-top {
    .sec-head-col {
        margin: 0 0 30px;
    }
}

.wf-active {
    .service-list li .box .block .title span { font-family: $font-en; }
}
.sec-service {}
.service-list {
    margin: 0 0 -40px;
    @include media(sm) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin: 0 0 -40px -40px !important;
    }
    li {
        padding: 0 0 40px;
        max-width: 100%;
        @include media(sm) {
            padding: 0 0 40px 40px;
            flex: 0 0 50%;
            min-width: 0;
            max-width: 50%;
            width: 50%;
        }
        .box {
            display: flex;
            flex-flow: row nowrap;
            figure {
                margin: -20px 0 0;
                z-index: 1;
                a {
                    display: block;
                    border-radius: 100px;
                    padding: 5px;
                    background-color: white;
                    width: 80px;
                    height: 80px;
                    @include media(md) {
                        padding: 10px;
                        width: 140px;
                        height: 140px;
                    }
                }
                img {
                    display: block;
                    border-radius: 100px;
                    border: 1px solid $black;
                    width: 70px;
                    height: 70px;
                    @include media(md) {
                        width: 120px;
                        height: 120px;
                    }
                }
            }
            .block {
                padding: 10px 0 0 10px;
                @include media(md) {
                    padding: 20px 0 0 10px;
                }
                .title {
                    font-size: 0.875rem;
                    font-weight: normal;
                    line-height: 1.3;
                    span {
                        display: block;
                        font-size: 1rem;
                        font-weight: bold;
                        font-family: $font-load;
                        line-height: 1.3;
                        @include media(md) {
                            font-size: 1.25rem;
                        }
                    }
                    a {
                        color: $black;
                        &:hover {
                            color: $link;
                        }
                    }
                }
                p {
                    margin: 10px 0 0;
                    line-height: 1.5;
                }
            }
        }
    }
}

.wf-active {
    .facility-list .facility_info dt.tel,
    .facility-list .facility_info dd.tel { font-family: $font-en; }
}
.sec-facility {}
.facility-list {
    @include media(md) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    & > li {
        display: block;
        padding: 0 0 60px;
        width: 100%;
        @include media(md) {
            flex: 0 0 47%;
            min-width: 0;
            max-width: 47%;
            width: 47%;
        }
    }
    ul {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        padding: 0;
        list-style-type: none;
        li {
            padding: 3px 5px;
            margin: 1px 1px 0 0;
            line-height: 1.0;
            font-size: .75rem;
            color: white;
            border-radius: 5px 5px 0 0;
            &.type1 { background-color: $pana-blue; }
            &.type2 { background-color: $pana-green; }
            &.type3 { background-color: $pana-pink; }
            &.type4 { background-color: $pana-orange; }
        }
    }
    .box-head {
        display: block;
    }
    a:hover .title {
        color: $link;
    }
    .title {
        margin: 10px 0 0;
        line-height: 1.4;
        color: $black;
        font-size: 1.125rem;
    }
    .facility_info {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        dt {
            min-width: 0;
            max-width: 70px;
            width: 70px;
            line-height: 1.3;
            margin: 5px 0 0;
            &.tel {
                font-family: $font-load;
                font-weight: 700;
                font-size: 1.25rem;
            }
        }
        dd {
            min-width: 0;
            max-width: calc(100% - 70px);
            width: calc(100% - 70px);
            line-height: 1.3;
            margin: 5px 0 0;
            &.tel {
                font-family: $font-load;
                font-weight: 700;
                font-size: 1.25rem;
            }
        }
    }
}


.sec-recruit {
    background-color: $pana-yellow;
    @include media(md) {
        display: flex;
        flex-flow: row-reverse nowrap;
        justify-content: space-between;
        align-items: center;
    }
    .recruit-box {
        padding: 20px 5% 40px;
        width: 100%;
        @include media(md) {
            width: 33.3333%;
            padding: 0 0 0 10%;
        }
    }
    .recruit-pic {
        position: relative;
        width: 100%;
        min-height: 240px;
        max-height: 240px;
        @include media(md) {
            width: 66.6666%;
            min-height: 392px;
            max-height: 392px;
        }
        &:before {
            display: block;
            content: '';
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }
}


// 下層
.wf-active {
    .school-list li a .tel {
        font-family: $font-en;
    }
}
.school-list {
    border-top: 1px solid $pale;
    li {
        padding: 10px 0;
        border-bottom: 1px solid $pale;
        a {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px 0 0;
            &:hover {
                &::after {
                    border: 2px solid $link;
                    border-top-width: 0;
                    border-left-width: 0;
                }
                .title,
                .tel {
                    color: $link;
                }
            }
            &::after {
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                width: 15px;
                height: 15px;
                transform: rotate(-45deg) translate(0, -50%);
                border: 2px solid $black;
                border-top-width: 0;
                border-left-width: 0;
                content: '';
            }
            figure {
                width: 33.2%;
                flex: 0 0 33.2%;
            }
            .box {
                width: 61.8%;
                flex: 0 0 61.8%;
            }
            .title {
                font-size: calc(16px + 4*(100vw - 320px)/1280);
                font-weight: normal;
                line-height: 1.3;
                color: $black;
            }
            .tel {
                font-family: $font-load;
                font-weight: 700;
                font-size: calc(14px + 16*(100vw - 320px)/1280);
                color: $black;
            }
        }
    }
}

.school-service-list {
    border-top: 1px solid $pale;
    li {
        padding: 20px 0;
        border-bottom: 1px solid $pale;
        a {
            position: relative;
            display: block;
            padding: 0 30px 0 0;
            @include media(md) {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
            }
            &:hover {
                &::after {
                    border: 2px solid $link;
                    border-top-width: 0;
                    border-left-width: 0;
                }
                .title,
                p {
                    color: $link;
                }
            }
            &::after {
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                width: 15px;
                height: 15px;
                transform: rotate(-45deg) translate(0, -50%);
                border: 2px solid $black;
                border-top-width: 0;
                border-left-width: 0;
                content: '';
            }
            figure {
                @include media(md) {
                    width: 33.2%;
                    flex: 0 0 33.2%;
                }
            }
            .box {
                margin: 10px 0 0;
                @include media(md) {
                    margin: 0;
                    width: 61.8%;
                    flex: 0 0 61.8%;
                }
            }
            .title {
                font-size: calc(16px + 4*(100vw - 320px)/1280);
                font-weight: bold;
                color: $black;
            }
            p {
                font-weight: normal;
                color: $black;
            }
        }
    }
}


.recruit-list {
    border-top: 1px solid $pale;
    li {
        padding: 20px 0;
        border-bottom: 1px solid $pale;
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        figure {
            width: 33.2%;
            flex: 0 0 33.2%;
        }
        .box {
            width: 61.8%;
            flex: 0 0 61.8%;
        }
        .title {
            font-size: calc(16px + 10*(100vw - 320px)/1280);
            font-weight: normal;
            line-height: 1.3;
        }
        .place {
            margin: 5px 0 0;
            span {
                display: inline-block;
                padding: 2px 10px;
                font-size: .875rem;
                line-height: 1.3;
                margin: 5px 5px 0 0;
                background-color: $pana-yellow;
                border-radius: 100px;
            }
        }
    }
}

.list-flow {
    li {
        position: relative;
        display: block;
        margin: 0;
        padding: 20px;
        max-width: 100%;
        color: $black;
        border-bottom: solid 3px $main;
        background-color: $thin;
        @include media(md) {
            padding: 40px;
        }
        &:not(:last-child) {
            &:before {
                content: "";
                position: absolute;
                bottom: -24px;
                left: 50%;
                margin-left: -15px;
                border: 12px solid transparent;
                border-top: 12px solid $thin;
                z-index: 2;
            }
            &:after {
                content: "";
                position: absolute;
                bottom: -30px;
                left: 50%;
                margin-left: -17px;
                border: 14px solid transparent;
                border-top: 14px solid $main;
                z-index: 1;
            }
        }
        &:not(:first-child) {
            margin-top: 30px;
        }
    }
}

.sec-childstudio-lesson2 {
    .title-leftline {
        span {
            display: inline-block;
            padding: 2px 10px;
            font-size: .875rem;
            line-height: 1.3;
            margin: 5px 5px 0 0;
            background-color: $pana-yellow;
            border-radius: 100px;
        }
    }
}


.sec-childstudio1 {
    .block-half {
        .detail {
            padding: 00;
            @include media(md) {
                padding: 0 30px 0 0;
            }
        }
        .title {
            font-size: calc(30px + 10*(100vw - 320px)/1280);
            color: $pana-pink;
            font-weight: bold;
            span {
                border-bottom: 10px solid $pana-yellow;
            }
        }
    }
}

.sec-childstudio2 {
    h2 {
        font-size: 1rem;
        color: $pana-blue;
        span {
            display: block;
            font-size: calc(20px + 10*(100vw - 320px)/1280);
        }
    }
    .block-half {
        @include media(md) {
            align-items: flex-start;
        }
    }
    .detail {
        padding: 20px;
        @include media(md) {
            width: 50%;
            flex: 0 0 50%;
            padding: 30px;
        }
        &.tennis {
            background-color: $pana-orange;
        }
        &.training {
            background-color: $pana-skyblue;
        }
        h3 {
            font-size: calc(20px + 10*(100vw - 320px)/1280);
            text-align: center;
            color: white;
        }
        p {
            color: white;
            em {
                color: white;
            }
        }
    }
}

.wf-active {
    .dl-faq dt::before,
    .dl-faq dd::before {
        font-family: $font-en;
    }
}
.dl-faq {
    margin: 0 0 40px;
    border: 5px solid $pale;
    padding: 30px;
    dt {
        position: relative;
        padding: 0 0 20px 40px;
        margin: 0 0 20px;
        font-weight: bold;
        border-bottom: 1px solid $pale;
        &::before {
            position: absolute;
            content: 'Q';
            font-family: $font-load;
            font-size: 2rem;
            font-weight: bold;
            display: inline-block;
            line-height: 1.0;
            top: 0;
            left: 0;
            color: $pana-pink;
        }
    }
    dd {
        position: relative;
        padding: 0 0 0 40px;
        &::before {
            position: absolute;
            content: 'A';
            font-family: $font-load;
            font-size: 2rem;
            font-weight: bold;
            display: inline-block;
            line-height: 1.0;
            top: 0;
            left: 0;
            color: $pana-blue;
        }
    }
}



























// ----------------------------------------
// フッター
// ----------------------------------------

.wf-active {
    .footer {
        &-foot {
            .copyright {
                font-family: $font-en;
            }
        }
        &-link {
            .link-list {
                li {
                    a {
                        span {
                            font-family: $font-en;
                        }
                    }
                }
            }
        }
    }
}
.footer {
    padding: 20px;
    margin: 120px 0 0;
    background-color: $pana-skyblue;
    @include media(md) {
        padding: 60px;
    }
    .page-id-5 & {
        margin: 0;
    }
    &-head {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }
    &-body {
        @include media(md) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
    }
    &-foot {
        .copyright {
            margin: 20px 0 0;
            text-align: right;
            color: darken($pana-skyblue, 30%);
            font-size: .875rem;
            line-height: 1.0;
            font-family: $font-load;
        }
    }
    &-logo {
        width: calc(180px + 126*(100vw - 320px)/1280);
        height: auto;
    }
    &-sns {
        padding: 0 0 0 20px;
        text-align: right;
        a {
            display: inline-block;
            border-radius: 100px;
            background-color: white;
            color: $pana-skyblue;
            padding: 10px;
            line-height: 1.0;
            &:hover {
                background-color: darken($pana-skyblue, 20%);
                color: white;
            }
            svg {
                width: 20px;
                height: 20px;
                fill: currentColor;
            }
        }
    }
    &-address {
        margin: 20px 0 0;
        width: 100%;
        @include media(md) {
            width: 45%;
        }
        .address-list {
            li {
                font-size: 0.875rem;
                line-height: 1.5;
                color: white;
                &:not(:first-child) {
                    margin: 20px 0 0;
                }
                a {
                    font-weight: bold;
                    color: white;
                    &:hover {
                        color: darken($pana-skyblue, 30%);
                    }
                }
                span {
                    white-space: nowrap;
                }
            }
        }
    }
    &-link {
        margin: 20px 0 0;
        width: 100%;
        @include media(md) {
            width: 50%;
        }
        .link-list {
            @include media(md) {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: baseline;
            }
            & > li {
                width: 100%;
                flex: 0 0 50%;
                @include media(md) {
                    width: 50%;
                }
                & + li {
                    margin: 30px 0 0;
                    @include media(md) {
                        margin: 0;
                    }
                }
                & > p {
                    font-size: 1rem;
                    line-height: 1.5;
                    color: white;
                    @include media(md) {
                        font-size: 0.75rem;
                    }
                }
                .list-child-lv2 {
                    li {
                        font-size: 1rem;
                        line-height: 1.5;
                        color: white;
                        margin: 10px 0 0;
                        @include media(md) {
                            font-size: 0.75rem;
                        }
                    }
                }
            }
            .separate {
                margin: 10px 0 0;
                @include media(md) {
                    margin: 30px 0 0;
                }
            }
            a {
                display: inline-block;
                position: relative;
                color: white;
                padding: 0 0 0 10px;
                &:hover {
                    color: darken($pana-skyblue, 30%);
                    &::before {
                    border-color: darken($pana-skyblue, 30%);
                    }
                }
                &::before {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 5px;
                    height: 5px;
                    transform: rotate(-45deg) translate(0, -50%);
                    border: 2px solid white;
                    border-top-width: 0;
                    border-left-width: 0;
                    content: '';
                }
            }
        }
    }
}







// ----------------------------------------
// 特殊
// ----------------------------------------


// grobal nav
.is-animation #gnav {
    display: inline-block;
}
.gnav--burger {
    position: fixed;
    top: 40px;
    right: 20px;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    z-index: 1000;
    display: block;
    @include media(md) {
        top: 30px;
        right: 30px;
        display: none;
    }
    &.active {
        div {
            &:nth-of-type(2) {
                opacity: 0;
            }
        }
    }
    &-icon {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 16px;
        div {
            position: absolute;
            display: inline-block;
            transition: transform .3s;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $black;
            &:nth-of-type(1) {
                top: 0;
                -webkit-animation: burger-bar01 .75s forwards;
                animation: burger-bar01 .75s forwards;
            }
            &:nth-of-type(2) {
                top: 7px;
                opacity: 1;
            }
            &:nth-of-type(3) {
                bottom: 0;
                -webkit-animation: burger-bar03 .75s forwards;
                animation: burger-bar03 .75s forwards;
            }
        }
    }
    &-text {
        display: block;
        font-size: 0.75rem;
        color: $black;
        line-height: 1;
        font-weight: 400;
    }
}
@-webkit-keyframes burger-bar01 {
    0% { -webkit-transform: translateY(7px) rotate(45deg); }
    50% { -webkit-transform: translateY(7px) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(0); }
}
@keyframes burger-bar01 {
    0% { transform: translateY(7px) rotate(45deg); }
    50% { transform: translateY(7px) rotate(0); }
    100% { transform: translateY(0) rotate(0); }
}
@-webkit-keyframes burger-bar03 {
    0% { -webkit-transform: translateY(-7px) rotate(-45deg); }
    50% { -webkit-transform: translateY(-7px) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(0); }
}
@keyframes burger-bar03 {
    0% { transform: translateY(-7px) rotate(-45deg); }
    50% { transform: translateY(-7px) rotate(0); }
    100% { transform: translateY(0) rotate(0); }
}


// drawer
.scroll-prevent {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.drawermenu {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    width: 85vw;
    height: 100%;
    overflow: auto;
    background-color: $thin;
    transition: opacity .3s ease-out;
    padding: 30px calc(20px + 20*(100vw - 320px)/1280) 30px calc(20px + 20*(100vw - 320px)/1280);
    opacity: 0;
    -webkit-overflow-scrolling: touch;
    text-align: right;
    animation: drawermenu 1s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
    @include media(sm) {
        width: 60vw;
    }
    @include media(md) {
        width: 40vw;
    }
    &.hide {
        opacity: 0;
    }
    &.is-active {
        opacity: 1;
        z-index: 2000;
    }
    .drawermenu--burger-icon {
        cursor: pointer;
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        div {
            position: absolute;
            display: inline-block;
            transition: transform .3s;
            left: 0;
            width: 20px;
            height: 2px;
            background-color: $black;
            &:nth-of-type(1) {
                top: 50%;
                transform: rotate(45deg) translate(-50%, -50%);
                -webkit-animation: drawer-bar01 .75s forwards;
                animation: drawer-bar01 .75s forwards;
            }
            &:nth-of-type(2) {
                opacity: 0;
            }
            &:nth-of-type(3) {
                top: 50%;
                transform: rotate(-45deg) translate(-50%, -50%);
                -webkit-animation: drawer-bar03 .75s forwards;
                animation: drawer-bar03 .75s forwards;
            }
        }
    }
}
@-webkit-keyframes drawer-bar01 {
    0% { -webkit-transform: translateY(0) rotate(0); }
    50% { -webkit-transform: translateY(0) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(45deg); }
}
@keyframes drawer-bar01 {
    0% { transform: translateY(0) rotate(0); }
    50% { transform: translateY(0) rotate(0); }
    100% { transform: translateY(0) rotate(45deg); }
}
@-webkit-keyframes drawer-bar03 {
    0% { -webkit-transform: translateY(0) rotate(0); }
    50% { -webkit-transform: translateY(0) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(-45deg); }
}
@keyframes drawer-bar03 {
    0% { transform: translateY(0) rotate(0); }
    50% { transform: translateY(0) rotate(0); }
    100% { transform: translateY(0) rotate(-45deg); }
}


.drawer-nav--section .tel {
    font-family: $font-en;
}


.drawer-nav {
    &--section {
        border-top: 1px solid $pale;
        padding-top: 20px;
        text-align: left;
        &-first {
            border-top: none;
            padding-top: 0;
        }
        .address {
            font-size: 0.875rem;
            line-height: 1.4;
            color: $aux;
            span {
                font-size: 0.75rem;
                display: block;
                color: $aux;
            }
        }
        .tel {
            font-size: calc(20px + 10*(100vw - 320px)/1280);
            font-weight: 700;
            line-height: 1.3;
            a {
                color: $link;
            }
            span {
                display: block;
                font-size: 0.875rem;
                font-weight: 500;
            }
        }
    }
    &--title {
        font-size: calc(14px + 4*(100vw - 320px)/1280);
        margin-bottom: 10px;
        a {
            color: $black;
            transition: color .3s ease-in-out;
            &:hover {
                color: $link;
            }
        }
    }
    &--list {}
    &--item {
        line-height: 1.4;
        &:not(:first-child){
            margin-top: 10px;
        }
        a {
            color: $black;
            font-size: calc(14px + 4*(100vw - 320px)/1280);
            transition: color .3s ease-in-out;
            &:hover {
                color: $link;
            }
            svg {
                height: 12px;
                width: auto;
                margin: 0 0 0 5px;
            }
        }
        .toggle {
            cursor: pointer;
            &:hover {
                span {
                    color: $link;
                    &:after {
                        border-top: 4px solid $link;
                    }
                }
            }
            span {
                display: inline-block;
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                line-height: 1.3;
                color: $black;
                transition: color .3s ease-in-out;
                &:after {
                    display: inline-block;
                    content: '';
                    width: 4px;
                    height: 4px;
                    border: 4px solid transparent;
                    border-top: 4px solid $black;
                    margin-left: 5px;
                    vertical-align: middle;
                    transition: border-top .3s ease-in-out;
                }
            }
            &.active {
                span {
                    color: $link;
                    &:after {
                        border-bottom: 4px solid $link;
                        border-top: none;
                    }
                }
            }
        }
        .child {
            padding-left: calc(10px + 20*(100vw - 320px)/1280);
            margin: 0 0 10px;
            line-height: 1.3;
            &.hide {
                display: none;
            }
            &--item {
                margin-top: 10px;
            }
        }
    }
}


.page-cover {
    display: none;
    &.is-active {
        display: block;
        z-index: 1900;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.6);
    }
}


// contentbottom
.sec-contentbottom {
    margin-top: calc(80px + 80*(100vw - 320px)/1280);
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    @include media(md) {
        max-width: 60%;
    }
    &--title {
        font-size: calc(18px + 12*(100vw - 320px)/1280);
        text-align: center;
    }
    .swiper-contentbottom {
        padding: 0;
    }
    .swiper-slide {
        margin: 0;
    }
    .swiper-pagination-contentbottom {
        text-align: center;
        .swiper-pagination-bullet {
            width: 20px;
            height: 2px;
            border-radius: 0;
        }
        .swiper-pagination-bullet-active {
            background: $key;
        }
    }
}

.card-contentbottom {
    .caption {
        margin-top: calc(10px + 10*(100vw - 320px)/1280);
        .title {
            font-size: calc(14px + 4*(100vw - 320px)/1280);
            line-height: 1.3;
            font-weight: normal;
            color: $black;
            span {
                font-weight: bold;
                display: block;
            }
        }
    }
}

// slick

.slick-dots {
    bottom: 10vh;
    padding: 0 10%;
    display: flex;
    justify-content: space-between;
    li {
        width: 40%;
        height: 6px;
        @include media(md) {
            height: 3px;
        }
        &.slick-active {
            background-color: #fff;
            button:before {
                opacity: 1;
                left: 0;
                -webkit-animation: slick-bar 5s linear;
                animation: slick-bar 5s linear;
                background-color: $key;
            }
        }
        button:before {
            content: '';
            background-color: $black;
            opacity: 0.4;
            width: 100%;
            height: 6px;
            @include media(md) {
                height: 3px;
            }
        }
    }
}
@-webkit-keyframes slick-bar {
    0% { width: 0; }
    100% { width: 100%; }
}
@keyframes slick-bar {
    0% { width: 0; }
    100% { width: 100%; }
}

.slider:hover {
    .slick-next {
        right: 3%;
    }
    .slick-prev {
        left: 3%;
    }
}
.slick-next {
    right: -50px;
    transition: right .3s ease-in-out;
    &:hover:before {
        opacity: 1;
    }
}
.slick-prev {
    left: -50px;
    transition: left .3s ease-in-out;
    z-index: 1;
    &:hover:before {
        opacity: 1;
    }
}
.slick-prev:before,
.slick-next:before {
    font-size: calc(20px + 20*(100vw - 320px)/1280);
    opacity: .6;
    transition: opacity .3s ease-out;
    text-shadow: $aux 0 0 10px;
}


// swiper
.swiper-container {
    padding: 0 5%;
    @include media(md) {
        padding: 0 10%;
    }
    &.swiper-hero {
        padding: 0;
        .card {
            min-height: 100vh;
            max-height: 100vh;
        }
    }
    .swiper-slide {
        flex: 0 0 auto;
        margin: 0 auto;
        &.card-title {
            min-width: 90%;
            max-width: 90%;
            width: 90%;
            @include media(md) {
                min-width: 70%;
                max-width: 70%;
                width: 70%;
            }
            @include media(lg) {
                min-width: 45%;
                max-width: 45%;
                width: 45%;
            }
        }
        &.card-lg {
            min-width: 90%;
            max-width: 90%;
            width: 90%;
        }
        &.card-md {
            min-width: 90%;
            max-width: 90%;
            width: 90%;
            @include media(md) {
                min-width: 50%;
                max-width: 50%;
                width: 50%;
            }
        }
        &.card-sm {
            min-width: 80%;
            max-width: 80%;
            width: 80%;
            @include media(sm) {
                min-width: 60%;
                max-width: 60%;
                width: 60%;
            }
            @include media(md) {
                min-width: 50%;
                max-width: 50%;
                width: 50%;
            }
            @include media(lg) {
                min-width: 30%;
                max-width: 30%;
                width: 30%;
            }
        }
    }
    a.card {
        &:hover {
            .pic {
                transform: scale(1.05);
            }
        }
    }
    .card {
        .pic-outer {
            overflow: hidden;
            position: relative;
            display: block;
            padding-top: 61.8%;
            content: '';
        }
        .pic {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            transition: transform .3s, filter .3s;
        }
        .caption-title {
            position: absolute;
            left: 5vw;
            bottom: 10%;
            .title {
                color: white;
                font-size: calc(18px + 22*(100vw - 320px)/1280);
                line-height: 1.3;
                text-shadow: $black 0 0 10px;
                font-weight: 500;
            }
            .text {
                color: white;
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                line-height: 1.4;
                margin-top: 10px;
                text-shadow: $black 0 0 10px;
            }
            .button {
                margin-top: 20px;
            }
        }
        .caption-body {
            padding: 30px 8%;
            .title {
                color: $black;
                font-size: 20px;
                line-height: 1.3;
            }
            .text {
                color: $black;
                font-size: calc(14px + 2*(100vw - 320px)/1280);
                margin-top: 10px;
            }
        }
        .caption-headline-item {
            padding: 20px 5px 0;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            .title {
                font-weight: normal;
                color: white;
                font-size: calc(18px + 4*(100vw - 320px)/1280);
                line-height: 1.3;
                text-shadow: $black 0 0 5px;
            }
        }
        .caption-number {
            padding-right: 5%;
            width: 100%;
            hgroup {
                display: flex;
                flex-direction: column;
            }
            .number {
                font-size: 2.5rem;
                font-weight: 400;
                line-height: 1.3;
                padding-bottom: 40px;
            }
            .title {
                font-size: calc(16px + 22*(100vw - 320px)/1280);
                font-weight: 400;
                line-height: 1.3;
                padding-bottom: 40px;
            }
            .text {
                text-align: justify;
            }
        }
        .caption-category {
            hgroup {
                display: flex;
                flex-direction: column;
            }
            .title {
                font-size: 0.875rem;
                line-height: 1.3;
                padding-bottom: 60px;
            }
            .detail {
                font-size: 1.875rem;
                line-height: 1.3;
                padding-bottom: 60px;
            }
        }
        .caption-text {
            padding: 0 5%;
        }
        .caption-desc {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: auto;
            background-color: white;
            border-bottom: 1px solid $pale;
            padding: 10px 20px;
            .text {
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                line-height: 1.3;
            }
        }
    }
    &:hover {
        .swiper-button-prev {
            left: 5%;
        }
        .swiper-button-next {
            right: 5%;
        }
    }
}

// swiper
.swiper-container2 {
    padding: 0 5%;
    @include media(md) {
        padding: 0 10%;
    }
    .swiper-slide {
        flex: 0 0 auto;
        margin: 0 auto;
        &.card-title {
            min-width: 90%;
            max-width: 90%;
            width: 90%;
            @include media(md) {
                min-width: 70%;
                max-width: 70%;
                width: 70%;
            }
            @include media(lg) {
                min-width: 45%;
                max-width: 45%;
                width: 45%;
            }
        }
        &.card-lg {
            min-width: 90%;
            max-width: 90%;
            width: 90%;
        }
        &.card-md {
            min-width: 90%;
            max-width: 90%;
            width: 90%;
            @include media(md) {
                min-width: 50%;
                max-width: 50%;
                width: 50%;
            }
        }
        &.card-sm {
            min-width: 80%;
            max-width: 80%;
            width: 80%;
            @include media(sm) {
                min-width: 60%;
                max-width: 60%;
                width: 60%;
            }
            @include media(md) {
                min-width: 50%;
                max-width: 50%;
                width: 50%;
            }
            @include media(lg) {
                min-width: 30%;
                max-width: 30%;
                width: 30%;
            }
            .card {
                min-height: 30vh;
                max-height: 30vh;
                height: 30vh;
            }
        }
    }
    a.card {
        &:hover {
            .pic {
                transform: scale(1.05);
            }
        }
    }
    .card {
        display: flex;
        align-items: center;
        position: relative;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        overflow: hidden;
        min-height: 65vh;
        max-height: 65vh;
        height: 65vh;
        @include media(sm) {
            min-height: 55vh;
            max-height: 55vh;
            height: 55vh;
        }
        @include media(md) {
            min-height: 40vh;
            max-height: 40vh;
            height: 40vh;
        }
        @include media(xl) {
            min-height: 55vh;
            max-height: 55vh;
            height: 55vh;
        }
        &:before {
            display: block;
            padding-top: 100%;
            content: '';
        }
        .pic {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            transition: transform .3s;
        }
        .caption-title {
            position: absolute;
            left: 5vw;
            bottom: 10%;
            .title {
                color: white;
                font-size: calc(18px + 22*(100vw - 320px)/1280);
                line-height: 1.3;
                text-shadow: $black 0 0 10px;
                font-weight: 500;
            }
            .text {
                color: white;
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                line-height: 1.4;
                margin-top: 10px;
                text-shadow: $black 0 0 10px;
            }
            .button {
                margin-top: 20px;

            }
        }
        .caption-headline-item {
            padding: 20px 5px 0;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            .title {
                font-weight: normal;
                color: white;
                font-size: calc(18px + 4*(100vw - 320px)/1280);
                line-height: 1.3;
                text-shadow: $black 0 0 5px;
            }
        }
        .caption-number {
            padding-right: 5%;
            width: 100%;
            &.pd-lg {
                padding: 0 10%;
            }
            hgroup {
                display: flex;
                flex-direction: column;
            }
            .number {
                font-size: 4.0rem;
                font-weight: 400;
                line-height: 1.3;
                padding-bottom: 40px;
            }
            .title {
                font-size: calc(20px + 20*(100vw - 320px)/1280);
                font-weight: 700;
                line-height: 1.3;
                padding-bottom: calc(20px + 20*(100vw - 320px)/1280);
            }
            .text {
                text-align: justify;
            }
        }
        .caption-category {
            hgroup {
                display: flex;
                flex-direction: column;
            }
            .title {
                font-size: 1.4rem;
                line-height: 1.3;
                padding-bottom: 60px;
            }
            .detail {
                font-size: 3.0rem;
                line-height: 1.3;
                padding-bottom: 60px;
            }
        }
        .caption-text {
            padding: 0 5%;
            &.pd-lg {
                padding: 0 10%;
            }
        }
        .caption-desc {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: auto;
            background-color: white;
            border-bottom: 1px solid $pale;
            padding: 10px 20px;
            .text {
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                line-height: 1.3;
            }
        }
    }
    &:hover {
        .swiper-button-prev {
            left: 5%;
        }
        .swiper-button-next {
            right: 5%;
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    width: calc(20px + 20*(100vw - 320px)/1280);
    height: calc(20px + 20*(100vw - 320px)/1280);
    margin-top: -5%;
    transition: left .3s ease-in-out, right .3s ease-in-out;
    opacity: .6;
    filter: drop-shadow(0 0 10px $aux);
    &:hover {
        opacity: 1;
    }
}
.swiper-button-prev {
    left: -20%;
    background: url(images/i-arrow-left.svg) no-repeat center center / cover;
    &.swiper-button-disabled {
        display: none;
    }
}
.swiper-button-next {
    right: -20%;
    background: url(images/i-arrow-right.svg) no-repeat center center / cover;
    &.swiper-button-disabled {
        display: none;
    }
}
.swiper-container-horizontal {
    & > .swiper-scrollbar {
        bottom: 0;
        border-radius: 0;
        width: 90%;
        left: 5%;
        position: relative;
        padding: calc(20px + 10*(100vw - 320px)/1280) 0;
        height: 2px;
        background: transparent 50%/2px url(images/bg-swiper-scrollbar.png) repeat-x !important;
        &:hover {
            .swiper-scrollbar-drag {
                &:after {
                    height: 6px;
                    top: -3px;
                    border-radius: 10px;
                }
            }
        }
        .swiper-scrollbar-drag {
            background-color: lighten($main, 20%);
            border-radius: 0;
            &:after {
                background: lighten($main, 20%);
                content: "";
                height: 2px;
                left: 0;
                position: absolute;
                right: 0;
                top: -1px;
                transition: height .2s ease-out, top .2s ease-in-out, border-radius .2s ease-in-out;
            }
        }
    }
}


/*
.loadingEffect {
    display: block;
    z-index: 100000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

.loadingEffect::before, .loadingEffect::after {
    display: block;
    z-index: 99;
    position: fixed;
    right: 0;
    left: 0;
    width: 100%;
    height: 50%;
    margin-right: auto;
    margin-left: auto;
    background-color: #F5F5F5;
    line-height: 1;
    content: ' ';
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    transition-delay: .3s;
}

.loadingEffect::before {
    top: 0;
}

.loadingEffect::after {
    bottom: 0;
}

.loaded .loadingEffect::before {
    height: 0;
    transform: translateY(-1%);
}

.loaded .loadingEffect::after {
    height: 0;
    transform: translateY(1%);
}
*/


