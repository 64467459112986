@charset "UTF-8";

// ========================================
// モジュール設定
// ========================================

// デザインスタイル
// ----------------------------------------


.sec {
    padding-left: 5%;
    padding-right: 5%;
    @include media(xl) {
        padding: 0;
    }
    &.bg-white {
        padding: 30px 20px;
        @include media(md) {
            padding: 60px;
        }
    }
    &.bg-thin {
        padding: 30px 20px;
        @include media(md) {
            padding: 60px;
        }
        background-color: $thin;
    }
    .wrapper {
        background-color: white;
        max-width: 960px;
        margin-right: auto;
        margin-left: auto;
        .inner {
            padding: 20px 5%;
            @include media(md) {
                padding: 60px 80px;
            }
        }
    }
    .inner {
        max-width: 960px;
        margin-right: auto;
        margin-left: auto;
    }
    .inner-wide {
        max-width: 1200px;
        margin-right: auto;
        margin-left: auto;
    }
    p {
        text-align: justify;
    }
    p + p {
        margin-top: 20px;
    }
    h1 + p, h2 + p, h1 + figure, h2 + figure {
        margin-top: 30px;
    }
    h3 + p, h4 + p, h3 + figure, h4 + figure {
        margin-top: 20px;
    }
    figure + p, p + figure {
        margin-top: calc(20px + 20*(100vw - 320px)/1280);
    }
    strong {
        color: $main;
        font-weight: bold;
        font-style: normal;
    }
    em {
        color: $main;
        font-weight: bold;
        font-style: normal;
    }
    b {
        font-weight: bold;
        font-style: normal;
    }
}
.sec-head-col {
    @include media(md) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    .title-row {
        margin: 0 40px 0 0;
    }
    .lead {
        margin: 10px 0 0;
        @include media(md) {
            margin: 0;
        }
    }
}



.sec-main {
    max-width: 960px;
    padding: 0 5%;
    margin-right: auto;
    margin-left: auto;
    @include media(xl) {
        padding: 0;
    }
}

.sec-clip {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    padding: 120px 10%;
    &-inner {
        background-color: white;
        padding: 80px 5%;
        border: 10px solid rgba(255, 255, 255, .3);
        background-clip: padding-box;
    }
    hgroup {
        background-clip: text;
        -webkit-background-clip: text;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center center;
    }
    .number {
        color: rgba(0, 0, 0, .4);
        font-size: calc(18px + 22*(100vw - 320px)/1280);
        font-weight: black;
        line-height: 1.3;
    }
    .title {
        span {
            color: rgba(0, 0, 0, .4);
            font-size: calc(18px + 22*(100vw - 320px)/1280);
            font-weight: black;
            line-height: 1.3;
        }
    }
}

.sec-bg {
    background-color: $thin;
    padding: calc(80px + 40*(100vw - 320px)/1280) 5%;
}




// block
.block-full-bg {
    & .pic {
        position: relative;
        width: 100%;
        @include media(md) {
            min-height: calc(320px + 360*(100vw - 320px)/1280);
            max-height: calc(320px + 360*(100vw - 320px)/1280);
        }
        &:before {
            display: block;
            padding-top: 100%;
            content: '';
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }
}


.block-half {
    display: block;
    @include media(md) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }
    &.reverse {
        @include media(md) {
            flex-flow: row-reverse nowrap;
        }
    }
    & > .pic {
        width: 100%;
        min-width:0;
        @include media(md) {
            width: 50%;
            flex: 0 0 50%;
        }
    }
    & > .detail {
        padding: 20px 0;
        min-width:0;
        @include media(md) {
            padding: 0;
            width: 45%;
            flex: 0 0 45%;
        }
    }
}

.block-half-bg {
    display: block;
    @include media(md) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    &.reverse {
        @include media(md) {
            flex-flow: row-reverse nowrap;
        }
    }
    & > .pic {
        position: relative;
        width: 100%;
        min-width:0;
        @include media(md) {
            width: 50%;
            flex: 1 0 50%;
            min-height: calc(320px + 360*(100vw - 320px)/1280);
            max-height: calc(320px + 360*(100vw - 320px)/1280);
        }
        &:before {
            display: block;
            padding-top: 100%;
            content: '';
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }
    & > .detail {
        padding: calc(20px + 20*(100vw - 320px)/1280) 5%;
        min-width:0;
        @include media(md) {
            width: 50%;
            flex: 1 1 50%;
        }
    }
}

.block-half-nr {
    display: block;
    @include media(md) {
        display: flex;
        flex-flow: row nowrap;
        align-items: top;
        justify-content: space-between;
    }
    &.reverse {
        @include media(md) {
            flex-flow: row-reverse nowrap;
        }
    }
    & > .pic {
        min-width:0;
        width: 100%;
        @include media(md) {
            width: 47.5%;
            flex: 0 0 47.5%;
        }
    }
    & > .detail {
        margin-top: 30px;
        min-width:0;
        width: 100%;
        @include media(md) {
            margin: 0;
            width: 47.5%;
            flex: 0 0 47.5%;
        }
    }
}


.block-memo {
    color: $aux;
}

.block-lead {
    text-align: center;
}

.block-basic {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.block-bg {
    background-color: $thin;
    padding: calc(20px + 20*(100vw - 320px)/1280);
    &.white {
        background-color: white;
    }
    &-md {
        padding: calc(20px + 40*(100vw - 320px)/1280);
    }
    &-lg {
        padding: calc(20px + 70*(100vw - 320px)/1280) calc(20px + 40*(100vw - 320px)/1280);
    }
}


.block-border {
    border: 5px solid $pale;
    padding: calc(10px + 30*(100vw - 320px)/1280);
}


.block-map {
    width: 100%;
    height: calc(480px + 200*(100vw - 320px)/1280);
}


.block-2c {
    .row {
        margin: -40px 0 0 0;
        @include media(sm) {
            display: flex;
            flex-flow: row wrap;
            margin: -60px 0 0 -40px;
        }
    }
    .col {
        padding: 40px 0 0 0;
        @include media(sm) {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 60px 0 0 40px;
        }
    }
    .caption {
        margin-top: 20px;
        .title {
            font-size: 1.0rem;
            line-height: 1.3;
            font-weight: normal;
            color: $black;
            span {
                font-weight: bold;
                display: block;
            }
        }
    }
    a:hover .title {
        color: $link;
    }
    .title {
        font-size: calc(20px + 4*(100vw - 320px)/1280);
        line-height: 1.3;
        color: $black;
        font-weight: 700;
        margin-top: 20px;
        transition: color .3s;
    }
    .text {
        font-size: calc(14px + 2*(100vw - 320px)/1280);
        text-align: justify;
        margin-top: 10px;
    }
}

.block-3c {
    .row {
        margin: -40px 0 0 0;
        @include media(sm) {
            display: flex;
            flex-flow: row wrap;
            margin: -60px 0 0 -20px;
        }
    }
    .col {
        padding: 40px 0 0 0;
        @include media(sm) {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
            padding: 60px 0 0 20px;
        }
    }
    .caption {
        margin-top: 20px;
        .title {
            font-size: 1.0rem;
            line-height: 1.3;
            font-weight: normal;
            color: $black;
            span {
                font-weight: bold;
                display: block;
            }
        }
    }
    a:hover .title {
        color: $link;
    }
    .title {
        font-size: calc(20px + 4*(100vw - 320px)/1280);
        line-height: 1.3;
        color: $black;
        font-weight: 700;
        margin-top: 20px;
        transition: color .3s;
    }
    .text {
        font-size: calc(14px + 2*(100vw - 320px)/1280);
        text-align: justify;
        margin-top: 10px;
    }
}

.block-4c {
    .row {
        margin: -40px 0 0 0;
        @include media(sm) {
            display: flex;
            flex-flow: row wrap;
            margin: -40px 0 0 -40px;
        }
    }
    .col {
        padding: 40px 0 0;
        @include media(sm) {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 40px 0 0 40px;
        }
        @include media(md) {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
    .caption {
        margin-top: 20px;
        .title {
            font-size: 1.0rem;
            line-height: 1.3;
            font-weight: normal;
            color: $black;
            span {
                font-weight: bold;
                display: block;
            }
        }
    }
    a:hover .title {
        color: $link;
    }
    .title {
        font-size: calc(20px + 4*(100vw - 320px)/1280);
        line-height: 1.3;
        color: $black;
        font-weight: 700;
        margin-top: 20px;
        transition: color .3s;
    }
    .text {
        font-size: calc(14px + 2*(100vw - 320px)/1280);
        text-align: justify;
        margin-top: 10px;
    }
}
.block-bnr-em {
    &--anchor {
        position: relative;
        width: 100%;
        background-size: cover;
        display: block;
        .caption {
            padding-left: 10%;
            padding-right: 10%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            .title {
                font-size: 0.875rem;
                color: white;
                padding-bottom: 40px;
            }
            .copy {
                font-size: 2.5rem;
                font-weight: normal;
                color: white;
                line-height: 1.3;
            }
        }
    }
}
.grid {
    margin: 0 0 -30px;
    @include media(sm) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 0 -30px -30px;
    }
    &.reverse {
        flex-direction: row-reverse;
    }
    &.sm-reverse {
        @include media(sm) {
            flex-direction: row-reverse;
        }
    }
    &.mt-m {
        margin-bottom: -60px;
    }
    &.nowrap {
        flex-wrap: nowrap;
    }
    &.jc-center {
        justify-content: center;
    }
    &.jc-end {
        justify-content: flex-end;
    }
    &.jc-between {
        justify-content: space-between;
    }
    &.jc-around {
        justify-content: space-around;
    }
    &.ai-start {
        align-items: flex-start;
    }
    &.ai-end {
        align-items: flex-end;
    }
    &.ai-center {
        align-items: center;
    }
    &.sm-4 {
        .col {
            @include media(sm) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
    }
    &.sm-3 {
        .col {
            @include media(sm) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
        }
    }
    &.sm-2 {
        .col {
            @include media(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    &.sm-2 {
        .col {
            @include media(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    &.md-4 {
        .col {
            @include media(md) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
    }
    &.md-3 {
        .col {
            @include media(md) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
        }
    }
    &.md-2 {
        .col {
            @include media(md) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    &.md-2 {
        .col {
            @include media(md) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    &.lg-4 {
        .col {
            @include media(lg) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
    }
    &.lg-3 {
        .col {
            @include media(lg) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
        }
    }
    &.lg-2 {
        .col {
            @include media(lg) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    &.lg-2 {
        .col {
            @include media(lg) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    &.mt-m {
        .col {
            padding-bottom: 60px;
        }
    }
    .col {
        padding: 0 0 30px;
        max-width: 100%;
        @include media(sm) {
            display: block;
            padding: 0 0 30px calc(20px + 10*(100vw - 320px)/1280);
            min-width: 0;
        }
        &.sm-1-2 {
            @include media(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        &.sm-1-3 {
            @include media(sm) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
        }
        &.sm-2-3 {
            @include media(sm) {
                flex: 0 0 66.6666%;
                max-width: 66.6666%;
            }
        }
        &.sm-1-4 {
            @include media(sm) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
        &.sm-3-4 {
            @include media(sm) {
                flex: 0 0 75%;
                max-width: 75%;
            }
        }
        &.md-1-2 {
            @include media(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        &.md-1-3 {
            @include media(md) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
        }
        &.md-2-3 {
            @include media(md) {
                flex: 0 0 66.6666%;
                max-width: 66.6666%;
            }
        }
        &.md-1-4 {
            @include media(md) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
        &.md-3-4 {
            @include media(md) {
                flex: 0 0 75%;
                max-width: 75%;
            }
        }
        &.lg-1-2 {
            @include media(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        &.lg-1-3 {
            @include media(lg) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
        }
        &.lg-2-3 {
            @include media(lg) {
                flex: 0 0 66.6666%;
                max-width: 66.6666%;
            }
        }
        &.lg-1-4 {
            @include media(lg) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
        &.lg-3-4 {
            @include media(lg) {
                flex: 0 0 75%;
                max-width: 75%;
            }
        }
    }
}




// btn
a:hover {
    .button {
        border: 1px solid $link;
        background-color: white;
        color: $link;
    }
}
.button {
    display: inline-block;
    padding: 10px 20px;
    max-width: 240px;
    position: relative;
    border-radius: 100px;
    border: 1px solid $link;
    background-color: $link;
    color: white;
    text-align: center;
    line-height: 1.3;
    svg {
        margin: 0 0 -1px 10px;
        width: 10px;
        height: 16px;
        fill: currentColor;
    }
}

.btn {
    a {
        display: inline-block;
        padding: 12px 20px;
        min-width: 160px;
        position: relative;
        border-radius: 100px;
        border: 3px solid $black;
        color: $black;
        text-align: center;
        line-height: 1.3;
        font-weight: bold;
        @include media(md) {
            padding: 15px 40px;
        }
        &:hover {
            border-color: $link;
            color: $link;
        }
        svg {
            margin: 0 0 -1px 5px;
            width: 16px;
            height: 16px;
            fill: currentColor;
        }
    }
}

.btn-bit {
    a {
        display: inline-block;
        padding: 0 10px 3px;
        border-radius: 100px;
        background: white;
        color: $black;
        line-height: 1.3;
        &:hover {
            color: $link;
        }
        svg {
            margin: 0 0 -2px;
            width: 7px;
            height: 12px;
            fill: currentColor;
        }
    }
}




.title-row {
    font-size: calc(14px + 2*(100vw - 320px)/1280);
    span {
        display: block;
        font-size: calc(30px + 10*(100vw - 320px)/1280);
        font-weight: 700;
        line-height: 1.3;
    }
}

.title-main {
    font-size: calc(20px + 20*(100vw - 320px)/1280);
    line-height: 1.3;
    font-weight: 700;
}

.title-sub {
    font-size: calc(16px + 4*(100vw - 320px)/1280);
    line-height: 1.5;
    font-weight: 700;
}
.title-xl {
    font-size: calc(30px + 10*(100vw - 320px)/1280);
    line-height: 1.5;
    font-weight: 700;
}

.title-lg {
    font-size: calc(20px + 10*(100vw - 320px)/1280);
    line-height: 1.5;
    font-weight: 700;
    span {
        display: block;
        font-size: 0.875rem;
        color: $key;
        margin-top: 10px;
    }
}
.title-md {
    font-size: calc(18px + 2*(100vw - 320px)/1280);
    line-height: 1.5;
    font-weight: 700;
    span {
        display: block;
        font-size: 0.875rem;
        color: $main;
        margin-top: 10px;
    }
}
.title-sm {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 700;
}
.title-xs {
    font-size: 16px;
    line-height: 1.8;
    font-weight: 700;
    @include media(xs) {
        line-height: 1.6;
    }
}

.title-line {
    position: relative;
    font-size: calc(20px + 20*(100vw - 320px)/1280);
    line-height: 1.3;
    padding: 0 0 calc(20px + 20*(100vw - 320px)/1280) 0;
    margin: 0 0 calc(20px + 10*(100vw - 320px)/1280) 0;
    text-align: center;
    span {
        display: block;
        font-size: 0.875rem;
        color: $main;
        margin-top: 10px;
    }
    &::before {
        position: absolute;
        display: block;
        content: '';
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: calc(30px + 30*(100vw - 320px)/1280);
        height: 2px;
        border-radius: 5px;
        background: $pale;
    }
}


.title-leftline {
    font-size: calc(20px + 10*(100vw - 320px)/1280);
    line-height: 1.3;
    font-weight: 700;
    padding: 10px 0 10px 30px;
    border-left: 5px solid $pana-blue;
}

.title-underline {
    font-size: calc(20px + 10*(100vw - 320px)/1280);
    line-height: 1.6;
    font-weight: 700;
    padding: 0 0 10px 0;
    border-bottom: 1px solid $black;
    @include media(xs) {
        line-height: 1.4;
    }
}

.title-square {
    position: relative;
    font-size: calc(20px + 20*(100vw - 320px)/1280);
    line-height: 1.6;
    padding: calc(10px + 10*(100vw - 320px)/1280) 0 calc(10px + 10*(100vw - 320px)/1280) calc(30px + 30*(100vw - 320px)/1280);
    @include media(xs) {
        line-height: 1.4;
    }
    span {
        display: block;
        font-size: 1rem;
        color: $aux;
        margin-top: 10px;
    }
    &::before {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 0;
        width: calc(10px + 10*(100vw - 320px)/1280);
        height: 100%;
        border-radius: 5px;
    }
}


.title-bg {
    width: 100%;
    vertical-align: middle;
    text-align: center;
    position: relative;
    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        min-height: calc(300px + 100*(100vw - 320px)/1280);
    }
    .title {
        font-size: calc(18px + 22*(100vw - 320px)/1280);
        text-align: center;
        color: white;
        font-weight: 400;
        letter-spacing: calc(5px + 5*(100vw - 320px)/1280);
        vertical-align: middle;
        display: inline-block;
    }
    .pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        z-index: -1;
    }
}

.title-blur {
    span {
        background-clip: text;
        -webkit-background-clip: text;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(images/bg-blur.jpg);
        color: rgba(0, 0, 0, .4);
        font-size: calc(18px + 12*(100vw - 320px)/1280);
        font-weight: 700;
        line-height: 1.3;
        @media all and (-ms-high-contrast: none){
            background-image: none;
            color: $black;
        }
    }
}


// text
.text-max {
    font-size: calc(30px + 30*(100vw - 320px)/1280);
    line-height: 1.4;
}
.text-xl {
    font-size: calc(20px + 20*(100vw - 320px)/1280);
    line-height: 1.4;
}
.text-lg {
    font-size: calc(20px + 10*(100vw - 320px)/1280);
    line-height: 1.4;
}
.text-md {
    font-size: calc(16px + 4*(100vw - 320px)/1280);
    line-height: 1.4;
}
.text-sm {
    font-size: calc(14px + 2*(100vw - 320px)/1280);
    line-height: 1.8;
}
.text-xs {
    font-size: calc(12px + 2*(100vw - 320px)/1280);
    line-height: 1.8;
}
.text-min {
    font-size: 12px;
    line-height: 1.8;
}

// function
.ft-line {
    position: relative;
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
    text-align: center;
    &::before {
        position: absolute;
        display: block;
        content: '';
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: calc(30px + 30*(100vw - 320px)/1280);
        height: 2px;
        border-radius: 5px;
        background: $pale;
    }
}



// grid
.grid-link {
    width: 90%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    @include media(md) {
        width: 80%;
    }
    &--list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }
    &--item {
        width: calc(50% - 5px);
        position: relative;
        margin-bottom: 10px;
    }
    &--item-full {
        width: 100%;
    }
    .main-title {
        font-size: 1.0rem;
        line-height: 1.3;
        margin-bottom: 10px;
    }
    .caption {
        position: absolute;
        top: 5%;
        width: 100%;
        .title {
            font-size: calc(16px + 8*(100vw - 320px)/1280);
            line-height: 1.3;
            text-align: center;
            color: white;
            text-shadow: $aux 0 0 10px;
            font-weight: 500;
        }
    }
}




// table

.table-wrap {
    @include media(xs) {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    th, td {
        @include media(xs) {
            white-space: nowrap;
        }
    }
}
.table-simple {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid $pale;
    tr {
        border-bottom: 1px solid $pale;
    }
    th {
        padding: calc(10px + 10*(100vw - 320px)/1280) calc(15px + 15*(100vw - 320px)/1280);
        font-weight: 400;
        line-height: 1.6;
    }
    td {
        padding: calc(10px + 10*(100vw - 320px)/1280) calc(15px + 15*(100vw - 320px)/1280);
        z-index: -1;
        line-height: 1.6;
    }
    thead {
    }
    tbody {
        th {
        }
    }
    &.xs-row {
        tr {
            @include media(xs) {
                display: block;
            }
        }
        th {
            @include media(xs) {
                display: block;
            }
        }
        td {
            @include media(xs) {
                display: block;
                padding: 10px 10px 20px;
            }
        }
        thead {
            @include media(xs) {
                display: block;
            }
        }
        tbody {
            @include media(xs) {
                display: block;
            }
        }
    }
    &.nowrap {
        th, td {
            white-space: nowrap;
        }
    }
    &.th-nowrap {
        th {
            white-space: nowrap;
        }
    }
    &.t-border {
        th, td {
            border: 1px solid $pale;
        }
    }
    &.bg-white {
        th, td {
            background-color: white;
        }
    }
    &.bg-none {
        thead {
                background-color: transparent;
        }
        th, td {
            background-color: transparent;
        }
    }
    &.t-noborder {
        border: none;
        th, td {
            border: none;
            padding: 10px;
        }
    }
    &.th-top tbody th,
    &.td-top tbody td {
        vertical-align: top;
    }
    &.th-middle tbody th,
    &.td-middle tbody td {
        vertical-align: middle;
    }
    &.th-bottom tbody th,
    &.td-bottom tbody td {
        vertical-align: bottom;
    }
    &.thead-left thead,
    &.t-left tbody,
    &.th-left tbody th,
    &.td-left tbody td {
        text-align: left;
    }
    &.thead-right thead,
    &.t-right tbody,
    &.th-right tbody th,
    &.td-right tbody td {
        text-align: right;
    }
    &.thead-center thead,
    &.t-center tbody,
    &.th-center tbody th,
    &.td-center tbody td {
        text-align: center;
    }
}

.table-row {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid $pale;
    line-height: 1.3;
    @include media(sm) {
        border-collapse: collapse;
    }
    thead {
        background-color: $thin;
    }
    th, td {
        border: 1px solid $pale;
        padding: 20px 10px;
        font-weight: 400;
        @include media(xs) {
            display: block;
            border: 0;
        }
    }
    th {
        @include media(xs) {
            padding: 15px 10px 10px;
        }
    }
    td {
        @include media(xs) {
            padding: 0 10px 15px;
        }
    }
    tr:not(:first-child) th {
        border-top: 1px solid $pale;
    }
}



// list

.list {
    &.sp-md {
        & > li + li {
            margin-top: 20px;
        }
    }
    &.sp-sm {
        & > li + li {
            margin-top: 10px;
        }
    }
}

.list-ori {
  & > li {
    position: relative;
    padding-left: 30px;
    span {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.list-circle {
  & > li {
    position: relative;
    padding-left: 18px;
    &:before {
      position: absolute;
      content: "●";
      top: 0;
      left: 0;
    }
  }
}
.list-asta {
  & > li {
    position: relative;
    padding-left: 18px;
    &:before {
      position: absolute;
      content: "＊";
      top: 0;
      left: 0;
    }
  }
}
.list-note {
  & > li {
    position: relative;
    padding-left: 18px;
    &:before {
      position: absolute;
      content: "※";
      top: 0;
      left: 0;
    }
  }
}
.list-disc {
  & > li {
    position: relative;
    padding-left: 14px;
    &:before {
      position: absolute;
      content: "・";
      top: 0;
      left: 0;
    }
  }
}

.list-number {
    list-style-type: none;
    list-style-position: inside;
}
    .list-number > li {
        padding-left: 1.5em;
        text-indent: -1.5em;
        counter-increment: cnt;
        text-align: justify;
    }
    .list-number > li:before {
        display: marker;
        content: "0" counter(cnt) " ";
        margin-right: 3px;
        color: $aux;
    }
    .list-number > li:nth-child(n+10):before {
        content: "" counter(cnt) " ";
    }


.list-link {
    display: block;
    overflow: hidden;
    @include media(sm) {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 0 -20px;
    }
    li {
        line-height: 1.5;
        @include media(sm) {
            margin: 10px 10px 0;
        }
        & + li {
            margin: 10px 0 0;
            @include media(sm) {
                margin: 10px 10px 0;
            }
        }
        a {
            display: block;
            background-color: white;
            border-radius: 10px;
            padding: 15px 30px;
            color: $black;
            transition: border .3s, color .3s;
            text-align: center;
            border: 1px solid white;
            &:hover {
                border: 1px solid $link;
                color: $link;
            }
            svg {
                margin: 0 0 -2px 5px;
                width: 7px;
                height: 14px;
                fill: currentColor;
            }
        }
    }
}

.list-anchor {
    display: block;
    overflow: hidden;
    @include media(sm) {
        display: flex;
        flex-flow: row wrap;
    }
    &.jc-center {
        @include media(sm) {
            justify-content: center;
        }
    }
    li {
        line-height: 1.5;
        margin: 0 0 10px;
        @include media(sm) {
            margin: 0 10px 10px 0;
        }
        span {
            display: block;
            background-color: white;
            border-radius: 50px;
            padding: 10px 20px;
            color: white;
            transition: border .3s, color .3s;
            text-align: center;
            background-color: $key;
            border: 3px solid $key;
            svg {
                margin: 0 0 -2px 5px;
                width: 7px;
                height: 14px;
                fill: currentColor;
            }
        }
        a {
            display: block;
            border-radius: 50px;
            padding: 10px 20px;
            color: $black;
            transition: background-color .3s,color .3s;
            text-align: center;
            border: 3px solid $black;
            font-weight: bold;
            &:hover {
                background-color: $link;
                color: white;
            }
            svg {
                margin: 0 0 -2px 5px;
                width: 7px;
                height: 14px;
                fill: currentColor;
            }
        }
    }
}


.list-flow-down {
    .flow {
        position: relative;
        display: block;
        margin: 0;
        padding: 20px 10px;
        min-width: 120px;
        max-width: 100%;
        color: white;
        background: $main;
        text-align: center;
        line-height: 1.3;
        border-radius: 10px;
        &:not(:last-child) {
            &:before {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -15px;
                border: 15px solid transparent;
                border-top: 15px solid $main;
            }
        }
        &:not(:first-child) {
            margin-top: 30px;
        }
    }
}

.list-flow-down-border {
    .flow {
        position: relative;
        display: block;
        margin: 0;
        padding: 20px;
        min-width: 120px;
        max-width: 100%;
        color: $black;
        border: solid 3px $main;
        background: white;
        text-align: center;
        line-height: 1.3;
        border-radius: 10px;
        &:not(:last-child) {
            &:before {
                content: "";
                position: absolute;
                bottom: -24px;
                left: 50%;
                margin-left: -15px;
                border: 12px solid transparent;
                border-top: 12px solid white;
                z-index: 2;
            }
            &:after {
                content: "";
                position: absolute;
                bottom: -30px;
                left: 50%;
                margin-left: -17px;
                border: 14px solid transparent;
                border-top: 14px solid $main;
                z-index: 1;
            }
        }
        &:not(:first-child) {
            margin-top: 30px;
        }
    }
}






// etc
a {
    &:hover {
        img.gray {
            filter: grayscale(0);
        }
    }
    img.gray {
        filter: grayscale(100%);
    }
}

.zoom {
    overflow: hidden;
    a:hover & {
        img {
            transform: scale(1.1);
            opacity: 1;
        }
    }
    img {
        transform: translate3d(0, 0, 0);
        transition: transform 5s;
    }
}

.tel-off {
    display: none !important;
    @include media(sm) {
        display: inline-block !important;
    }
}
.tel-on {
    display: inline-block !important;
    @include media(sm) {
        display: none !important;
    }
}

.fig-caption {
    margin: -10px 0;
    img {
        padding: 10px 0;
    }
    figcaption {
        margin: 10px 0;
        font-size: 86%;
        line-height: 1.4;
        color: $aux;
    }
}
.fig-caption-lg {
    margin: 1em calc(50% - 50vw);
    img {
        width: 100%;
        height: auto;
    }
    figcaption {
        margin: 10px auto;
        max-width: 960px;
        padding: 0 5%;
        font-size: 86%;
        line-height: 1.4;
        color: $aux;
    }
}
.release-pic {
    margin: 1em calc(50% - 50vw);
    .pic {
        width: 100%;
        height: 600px;
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .notes {
        margin: 10px auto;
        max-width: 960px;
        padding: 0 5%;
        font-size: 86%;
        line-height: 1.4;
        color: $aux;
    }
}

.dl-basic {
    @include media(md) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    dt {
        padding: 20px 0 0;
        color: $main;
        @include media(md) {
            flex: 0 0 28%;
            width: 30%;
            padding: 20px 0;
            border-bottom: 1px solid $main;
        }
    }
    dd {
        padding: 5px 0 20px;
        border-bottom: 1px solid $pale;
        @include media(md) {
            flex: 0 0 70%;
            width: 70%;
            padding: 20px 0;
        }
    }
}

.dl-price {
    @include media(md) {
        display: flex;
        flex-flow: row wrap;
    }
    dt {
        color: $main;
        @include media(md) {
            flex: 0 0 30%;
            width: 30%;
        }
    }
    dd {
        @include media(md) {
            flex: 0 0 70%;
            width: 70%;
        }
        &:before {
            content: ' …… ';
        }
    }
}




/* movie youtube
--------------------------------------------- */
.iframe-wrap {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 62.5%;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.video-wrap {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 62.5%;
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


/* sef
--------------------------------------------- */
.sef {
    opacity: 0;
    transition: all .5s cubic-bezier(.39,.575,.565,1);
}

.sef.sef-ori {
    opacity: 1;
}

.sef.show {
    opacity: 1;
    transform: none;
}

.sef-left {
transform: translate(-100px, 0);
}

.sef-right {
transform: translate(100px, 0);
}

.sef-up {
transform: translate(0, 100px);
}

.sef-down {
transform: translate(0, -100px);
}

.sef-scaleUp {
transform: scale(.5);
}

.sef-scaleDown {
transform: scale(1.5);
}


/* flexbox
--------------------------------------------- */
.as-center { align-self: center; }
.as-start { align-self: flex-start; }
.as-end { align-self: flex-end; }
.as-stretch { align-self: stretch; }
.as-baseline { align-self: baseline; }
.as-auto { align-self: auto; }


/* カラー
--------------------------------------------- */
.c-white { color: white !important; }
.c-black { color: $black !important; }
.c-aux { color: $aux !important; }
.c-pale { color: $pale !important; }
.c-thin { color: $thin !important; }
.c-main { color: $main !important; }
.c-key { color: $key !important; }
.c-link { color: $link !important; }
.c-em { color: $em !important; }
.c-pink { color: $pana-pink !important; }
.c-skyblue { color: $pana-skyblue !important; }
.c-blue { color: $pana-blue !important; }
.c-green { color: $pana-green !important; }
.c-yellow { color: $pana-yellow !important; }
.c-orange { color: $pana-orange !important; }


.bg-white { background-color: white !important; }
.bg-black { background-color: $black !important; }
.bg-aux { background-color: $aux !important; }
.bg-pale { background-color: $pale !important; }
.bg-thin { background-color: $thin !important; }
.bg-key { background-color: $key !important; }
.bg-key-thin { background-color: lighten($key, 30%) !important; }
.bg-gray { background-color: $gray !important; }



/* スペース
--------------------------------------------- */
.mt-skin  { margin-top:   5px !important; }
.mt-min   { margin-top:  10px !important; }
.mt-xs    { margin-top:  20px !important; }
.mt-sm    { margin-top:  30px !important; }
.mt-md    { margin-top:  calc(30px + 30*(100vw - 320px)/1280) !important; }
.mt-lg    { margin-top:  calc(60px + 20*(100vw - 320px)/1280) !important; }
.mt-xl    { margin-top:  calc(80px + 40*(100vw - 320px)/1280) !important; }
.mt-max   { margin-top:  calc(120px + 40*(100vw - 320px)/1280) !important; }

.mr-skin  { margin-right:   5px !important; }
.mr-min   { margin-right:  10px !important; }
.mr-xs    { margin-right:  20px !important; }
.mr-sm    { margin-right:  30px !important; }

.ml-skin  { margin-left:   5px !important; }
.ml-min   { margin-left:  10px !important; }
.ml-xs    { margin-left:  20px !important; }
.ml-sm    { margin-left:  30px !important; }

.mb-skin  { margin-bottom:   5px !important; }
.mb-min   { margin-bottom:  10px !important; }
.mb-xs    { margin-bottom:  20px !important; }
.mb-sm    { margin-bottom:  30px !important; }
.mb-md    { margin-bottom:  calc(40px + 20*(100vw - 320px)/1280) !important; }
.mb-lg    { margin-bottom:  calc(60px + 20*(100vw - 320px)/1280) !important; }
.mb-xl    { margin-bottom:  calc(80px + 40*(100vw - 320px)/1280) !important; }
.mb-max   { margin-bottom:  calc(120px + 40*(100vw - 320px)/1280) !important; }

.pt-skin  { padding-top:   5px !important; }
.pt-min   { padding-top:  10px !important; }
.pt-xs    { padding-top:  20px !important; }
.pt-sm    { padding-top:  30px !important; }

.pr-skin  { padding-right:   5px !important; }
.pr-min   { padding-right:  10px !important; }
.pr-xs    { padding-right:  20px !important; }
.pr-sm    { padding-right:  30px !important; }

.pl-skin  { padding-left:   5px !important; }
.pl-min   { padding-left:  10px !important; }
.pl-xs    { padding-left:  20px !important; }
.pl-sm    { padding-left:  30px !important; }

.pb-skin  { padding-bottom:   5px !important; }
.pb-min   { padding-bottom:  10px !important; }
.pb-xs    { padding-bottom:  20px !important; }
.pb-sm    { padding-bottom:  30px !important; }



// フォント設定
// ----------------------------------------
.wf-active {
    .ff-en { font-family: $font-en !important; }
}
.ff-en { font-family: $font-load; }

.fw-b { font-weight: 700 !important; }
.fw-n { font-weight: 400 !important; }

.fs-min { font-size:  72% !important; }
.fs-xs  { font-size:  86% !important; }
.fs-sm   { font-size:  93% !important; }
.fs-md   { font-size: 115% !important; }
.fs-lg  { font-size: 143% !important; }
.fs-xl {
    font-size: 143% !important;
    @include media(md) {
        font-size: 172% !important;
    }
}
.fs-max {
    font-size: 172% !important;
    @include media(md) {
        font-size: 300% !important;
    }
}


.fs10 { font-size: 0.625rem !important; }
.fs11 { font-size: 0.6875rem !important; }
.fs12 { font-size: 0.75rem !important; }
.fs13 { font-size: 0.8125rem !important; }
.fs14 { font-size: 0.875rem !important; }
.fs16 { font-size: 1.0rem !important; }
.fs18 { font-size: 1.125rem !important; }
.fs20 { font-size: 1.25rem !important; }
.fs24 { font-size: 1.5rem !important; }
.fs28 { font-size: 1.75rem !important; }
.fs30 { font-size: 1.875rem !important; }
.fs32 { font-size: 2.0rem !important; }
.fs36 { font-size: 2.25rem !important; }
.fs40 { font-size: 2.5rem !important; }
.fs48 { font-size: 3.0rem !important; }

.lh10 { line-height: 1.0 !important; }
.lh11 { line-height: 1.1 !important; }
.lh12 { line-height: 1.2 !important; }
.lh13 { line-height: 1.3 !important; }
.lh14 { line-height: 1.4 !important; }
.lh15 { line-height: 1.5 !important; }
.lh18 { line-height: 1.8 !important; }
.lh20 { line-height: 2.0 !important; }


// ツール設定
// ----------------------------------------
.ta-l { text-align: left !important;}
.ta-c { text-align: center !important;}
.ta-r { text-align: right !important;}
.ta-j { text-align: justify !important;}

.ta-cl {
    text-align: left !important;
    @include media(md) {
        text-align: center !important;
    }
}

@include media(xs) {
    .xs-w90c {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
    }
}

.xl-on {
    display: none;
    @include media(xl) {
        display: block;
    }
}
.xl-no {
    @include media(xl) {
        display: none;
    }
}
.lg-on {
    display: none;
    @include media(lg) {
        display: block;
    }
}
.lg-no {
    @include media(lg) {
        display: none;
    }
}
.md-on {
    display: none;
    @include media(md) {
        display: block;
    }
}
.md-no {
    @include media(md) {
        display: none;
    }
}
.sm-on {
    display: none;
    @include media(sm) {
        display: block;
    }
}
.sm-no {
    @include media(sm) {
        display: none;
    }
}
.xs-on {
    display: none;
    @include media(xs) {
        display: block;
    }
}
.xs-no {
    display: block;
    @include media(xs) {
        display: none;
    }
}


.d-block { display: block; }
.d-iblock { display: inline-block; }
.d-table { display: table; }
.d-tablec { display: table-cell; }
.d-inline { display: inline; }


.fl-r {
    @include media(sm) {
        float: right;
    }
}
.fl-l {
    @include media(sm) {
        float: left;
    }
}
figure.fl-r {
    margin: 0 0 20px;
    @include media(sm) {
        margin: 0 0 20px 20px;
    }
}
figure.fl-l {
    margin: 0 0 20px;
    @include media(sm) {
        margin: 0 20px 20px 0;
    }
}






// clearfix
// ----------------------------------------
.clearfix {
    display: block;
    min-height: 1%;
}
.clearfix:after {
    clear: both;
    content:"";
    display: block;
    height: 0;
    visibility: hidden;
}
* html .clearfix {
    height: 1%;
    /*\*//*/
    height: auto;
    overflow: hidden;
    /**/
}
.clear {
    clear: both;
}